"use strict";
declare function gtag(tag: string, action: string, parameters: object);
function setupVideoReplay() {
	const videos = document.querySelectorAll("video[autoplay]:not([loop])") as NodeListOf<HTMLVideoElement>;
	videos.forEach(video => video.addEventListener("ended", function (event) {
		const fragment = (document.querySelector("#template-replay") as HTMLTemplateElement).content.cloneNode(true) as DocumentFragment;
		const button = document.importNode(fragment.querySelector("button"), true) as HTMLButtonElement;
		const frame = this.parentElement;
		const video = this;
		const source = new URL(video.src);
		let animationFrame = 0;
		button.style.position = "absolute";
		frame.style.position = "relative";
		frame.appendChild(button);
		button.addEventListener("click", function (event) {
			cancelAnimationFrame(animationFrame);
			this.remove();
			video.play();
		});
		const updateButtonLocation = function (timestamp?: number) {
			const fbcr = frame.getBoundingClientRect();
			const vbcr = video.getBoundingClientRect();
			const bbcr = button.getBoundingClientRect();
			const x = fbcr.left - vbcr.left + vbcr.width - bbcr.width;
			const y = fbcr.top - vbcr.top + vbcr.height - bbcr.height;
			button.style.left = "calc(" + x + "px - 1em)";
			button.style.top = "calc(" + y + "px - 1.5em)";
			animationFrame = requestAnimationFrame(updateButtonLocation);
		};
		updateButtonLocation();
	}));
}
function setupStickyHeaders() {
	const main = document.querySelector(".site-main") as HTMLElement;
	const page = document.querySelector(".page") as HTMLElement;
	const header = page.querySelector(".page-header") as HTMLElement;
	if (header) {
		let collapseEnabled = true;
		let lastScrollTop = main.scrollTop;
		header.style.height = header.getBoundingClientRect().height + "px";
		window.addEventListener("resize", function (event) {
			header.style.height = "auto";
			window.setTimeout(function () {
				header.style.height = header.getBoundingClientRect().height + "px";
			}, 200);
		});
		main.addEventListener("scroll", function (event) {
			const delta = lastScrollTop - main.scrollTop
			if (delta < 0 && main.scrollTop > 0 && collapseEnabled) {
				header.classList.add("-collapsed");
				collapseEnabled = false;
				window.setTimeout(function () {
					collapseEnabled = true;
				}, 200);
			} else if (delta > 0 && main.scrollTop == 0 && collapseEnabled) {
				header.classList.remove("-collapsed");
			}
			lastScrollTop = main.scrollTop;
		});
		header.classList.add("-animated");
	}
}
setupVideoReplay();
setupStickyHeaders();
